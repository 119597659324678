@font-face {
  font-family: "Astra";
  src: url(/images/astra.woff) format("woff"), url(/images/astra.ttf) format("truetype"), url(https://staging.telltale.com/wp-content/themes/astra/assets/fonts/astra.svg#astra) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: fallback
}

html {
  font-size: 93.75%
}

a,
.page-title {
  color: #0274be
}

a:hover,
a:focus {
  color: #3a3a3a
}

body,
button,
input,
select,
textarea {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  font-size: 15px;
  font-size: 1rem
}

blockquote {
  color: #000
}

.site-title {
  font-size: 35px;
  font-size: 2.3333333333333rem
}

.ast-archive-description .ast-archive-title {
  font-size: 40px;
  font-size: 2.6666666666667rem
}

.site-header .site-description {
  font-size: 15px;
  font-size: 1rem
}

.entry-title {
  font-size: 40px;
  font-size: 2.6666666666667rem
}

.comment-reply-title {
  font-size: 24px;
  font-size: 1.6rem
}

.ast-comment-list #cancel-comment-reply-link {
  font-size: 15px;
  font-size: 1rem
}

h1,
.entry-content h1 {
  font-size: 40px;
  font-size: 2.6666666666667rem
}

h2,
.entry-content h2 {
  font-size: 30px;
  font-size: 2rem
}

h3,
.entry-content h3 {
  font-size: 25px;
  font-size: 1.6666666666667rem
}

h4,
.entry-content h4 {
  font-size: 20px;
  font-size: 1.3333333333333rem
}

h5,
.entry-content h5 {
  font-size: 18px;
  font-size: 1.2rem
}

h6,
.entry-content h6 {
  font-size: 15px;
  font-size: 1rem
}

.ast-single-post .entry-title,
.page-title {
  font-size: 30px;
  font-size: 2rem
}

#secondary,
#secondary button,
#secondary input,
#secondary select,
#secondary textarea {
  font-size: 15px;
  font-size: 1rem
}

::selection {
  background-color: #0274be;
  color: #fff
}

body,
h1,
.entry-title a,
.entry-content h1,
h2,
.entry-content h2,
h3,
.entry-content h3,
h4,
.entry-content h4,
h5,
.entry-content h5,
h6,
.entry-content h6 {
  color: #0a0a0a
}

.tagcloud a:hover,
.tagcloud a:focus,
.tagcloud a.current-item {
  color: #fff;
  border-color: #0274be;
  background-color: #0274be
}

.main-header-menu a,
.ast-header-custom-item a {
  color: #0a0a0a
}

.main-header-menu li:hover>a,
.main-header-menu li:hover>.ast-menu-toggle,
.main-header-menu .ast-masthead-custom-menu-items a:hover,
.main-header-menu li.focus>a,
.main-header-menu li.focus>.ast-menu-toggle,
.main-header-menu .current-menu-item>a,
.main-header-menu .current-menu-ancestor>a,
.main-header-menu .current_page_item>a,
.main-header-menu .current-menu-item>.ast-menu-toggle,
.main-header-menu .current-menu-ancestor>.ast-menu-toggle,
.main-header-menu .current_page_item>.ast-menu-toggle {
  color: #0274be
}

input:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="reset"]:focus,
input[type="search"]:focus,
textarea:focus {
  border-color: #0274be
}

input[type="radio"]:checked,
input[type="reset"],
input[type="checkbox"]:checked,
input[type="checkbox"]:hover:checked,
input[type="checkbox"]:focus:checked,
input[type="range"]::-webkit-slider-thumb {
  border-color: #0274be;
  background-color: #0274be;
  box-shadow: none
}

.site-footer a:hover+.post-count,
.site-footer a:focus+.post-count {
  background: #0274be;
  border-color: #0274be
}

.footer-adv .footer-adv-overlay {
  border-top-style: solid;
  border-top-color: #7a7a7a
}

.ast-comment-meta {
  line-height: 1.666666667;
  font-size: 12px;
  font-size: .8rem
}

.single .nav-links .nav-previous,
.single .nav-links .nav-next,
.single .ast-author-details .author-title,
.ast-comment-meta {
  color: #0274be
}

.menu-toggle,
button,
.ast-button,
.button,
input#submit,
input[type="button"],
input[type="submit"],
input[type="reset"] {
  border-radius: 2px;
  padding: 10px 40px;
  color: #fff;
  border-color: #0274be;
  background-color: #0274be
}

button:focus,
.menu-toggle:hover,
button:hover,
.ast-button:hover,
.button:hover,
input[type="reset"]:hover,
input[type="reset"]:focus,
input#submit:hover,
input#submit:focus,
input[type="button"]:hover,
input[type="button"]:focus,
input[type="submit"]:hover,
input[type="submit"]:focus {
  color: #fff;
  border-color: #3a3a3a;
  background-color: #3a3a3a
}

.entry-meta,
.entry-meta * {
  line-height: 1.45;
  color: #0274be
}

.entry-meta a:hover,
.entry-meta a:hover *,
.entry-meta a:focus,
.entry-meta a:focus * {
  color: #3a3a3a
}

.ast-404-layout-1 .ast-404-text {
  font-size: 200px;
  font-size: 13.333333333333rem
}

.widget-title {
  font-size: 21px;
  font-size: 1.4rem;
  color: #0a0a0a
}

#cat option,
.secondary .calendar_wrap thead a,
.secondary .calendar_wrap thead a:visited {
  color: #0274be
}

.secondary .calendar_wrap #today,
.ast-progress-val span {
  background: #0274be
}

.secondary a:hover+.post-count,
.secondary a:focus+.post-count {
  background: #0274be;
  border-color: #0274be
}

.calendar_wrap #today>a {
  color: #fff
}

.ast-pagination a,
.page-links .page-link,
.single .post-navigation a {
  color: #0274be
}

.ast-pagination a:hover,
.ast-pagination a:focus,
.ast-pagination>span:hover:not(.dots),
.ast-pagination>span.current,
.page-links>.page-link,
.page-links .page-link:hover,
.post-navigation a:hover {
  color: #3a3a3a
}

.ast-header-break-point .ast-mobile-menu-buttons-minimal.menu-toggle {
  background: transparent;
  color: #0274be
}

.ast-header-break-point .ast-mobile-menu-buttons-outline.menu-toggle {
  background: transparent;
  border: 1px solid #0274be;
  color: #0274be
}

.ast-header-break-point .ast-mobile-menu-buttons-fill.menu-toggle {
  background: #0274be
}

@media (min-width:769px) {
  .ast-small-footer .ast-container {
    max-width: 100%;
    padding-left: 35px;
    padding-right: 35px
  }
}

@media (min-width:545px) {

  .ast-page-builder-template .comments-area,
  .single.ast-page-builder-template .entry-header,
  .single.ast-page-builder-template .post-navigation {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto
  }
}

body,
.ast-separate-container {
  background-color: #0f111d
}

@media (max-width:768px) {
  .ast-archive-description .ast-archive-title {
    font-size: 40px
  }

  .entry-title {
    font-size: 30px
  }

  h1,
  .entry-content h1 {
    font-size: 30px
  }

  h2,
  .entry-content h2 {
    font-size: 25px
  }

  h3,
  .entry-content h3 {
    font-size: 20px
  }

  .ast-single-post .entry-title,
  .page-title {
    font-size: 30px
  }
}

@media (max-width:544px) {
  .ast-archive-description .ast-archive-title {
    font-size: 40px
  }

  .entry-title {
    font-size: 30px
  }

  h1,
  .entry-content h1 {
    font-size: 30px
  }

  h2,
  .entry-content h2 {
    font-size: 25px
  }

  h3,
  .entry-content h3 {
    font-size: 20px
  }

  .ast-single-post .entry-title,
  .page-title {
    font-size: 30px
  }
}

@media (max-width:768px) {
  html {
    font-size: 85.5%
  }
}

@media (max-width:544px) {
  html {
    font-size: 85.5%
  }
}

@media (min-width:769px) {
  .ast-container {
    max-width: 940px
  }
}

@media (max-width:921px) {
  .main-header-bar .main-header-bar-navigation {
    display: none
  }
}

.ast-desktop .main-header-menu.submenu-with-border .sub-menu,
.ast-desktop .main-header-menu.submenu-with-border .children,
.ast-desktop .main-header-menu.submenu-with-border .astra-full-megamenu-wrapper {
  border-color: #0274be
}

.ast-desktop .main-header-menu.submenu-with-border .sub-menu,
.ast-desktop .main-header-menu.submenu-with-border .children {
  border-top-width: 2px;
  border-right-width: 0;
  border-left-width: 0;
  border-bottom-width: 0;
  border-style: solid
}

.ast-desktop .main-header-menu.submenu-with-border .sub-menu .sub-menu,
.ast-desktop .main-header-menu.submenu-with-border .children .children {
  top: -2px
}

.ast-desktop .main-header-menu.submenu-with-border .sub-menu a,
.ast-desktop .main-header-menu.submenu-with-border .children a {
  border-bottom-width: 0;
  border-style: solid;
  border-color: #eaeaea
}

@media (min-width:769px) {

  .main-header-menu .sub-menu li.ast-left-align-sub-menu:hover>ul,
  .main-header-menu .sub-menu li.ast-left-align-sub-menu.focus>ul {
    margin-left: -0
  }
}

.ast-small-footer {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #0f111d
}

.ast-small-footer-wrap {
  text-align: center
}

@media (max-width:920px) {
  .ast-404-layout-1 .ast-404-text {
    font-size: 100px;
    font-size: 6.6666666666667rem
  }
}

.ast-header-break-point .site-header {
  border-bottom-width: 0
}

@media (min-width:769px) {
  .main-header-bar {
    border-bottom-width: 0
  }
}

.main-header-menu .menu-item,
.main-header-bar .ast-masthead-custom-menu-items {
  -js-display: flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.main-header-menu>.menu-item>a {
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -js-display: flex;
  display: flex
}

.ast-primary-menu-disabled .main-header-bar .ast-masthead-custom-menu-items {
  flex: unset
}

#masthead .ast-container,
.ast-header-breadcrumb .ast-container {
  max-width: 100%;
  padding-left: 35px;
  padding-right: 35px
}

@media (max-width:921px) {

  #masthead .ast-container,
  .ast-header-breadcrumb .ast-container {
    padding-left: 20px;
    padding-right: 20px
  }
}

#masthead .ast-container,
.ast-header-breadcrumb .ast-container {
  max-width: 100%;
  padding-left: 35px;
  padding-right: 35px
}

@media (max-width:921px) {

  #masthead .ast-container,
  .ast-header-breadcrumb .ast-container {
    padding-left: 20px;
    padding-right: 20px
  }
}

@media (min-width:769px) {
  .ast-theme-transparent-header #masthead {
    position: absolute;
    left: 0;
    right: 0
  }

  .ast-theme-transparent-header .main-header-bar,
  .ast-theme-transparent-header.ast-header-break-point .main-header-bar {
    background: none
  }

  body.elementor-editor-active.ast-theme-transparent-header #masthead,
  .fl-builder-edit .ast-theme-transparent-header #masthead,
  body.vc_editor.ast-theme-transparent-header #masthead {
    z-index: 0
  }

  .ast-header-break-point.ast-replace-site-logo-transparent.ast-theme-transparent-header .custom-mobile-logo-link {
    display: none
  }

  .ast-header-break-point.ast-replace-site-logo-transparent.ast-theme-transparent-header .transparent-custom-logo {
    display: inline-block
  }

  .ast-theme-transparent-header .ast-above-header {
    background-image: none;
    background-color: transparent
  }

  .ast-theme-transparent-header .ast-below-header {
    background-image: none;
    background-color: transparent
  }
}

@media (max-width:768px) {
  .ast-theme-transparent-header #masthead {
    position: absolute;
    left: 0;
    right: 0
  }

  .ast-theme-transparent-header .main-header-bar,
  .ast-theme-transparent-header.ast-header-break-point .main-header-bar {
    background: none
  }

  body.elementor-editor-active.ast-theme-transparent-header #masthead,
  .fl-builder-edit .ast-theme-transparent-header #masthead,
  body.vc_editor.ast-theme-transparent-header #masthead {
    z-index: 0
  }

  .ast-header-break-point.ast-replace-site-logo-transparent.ast-theme-transparent-header .custom-mobile-logo-link {
    display: none
  }

  .ast-header-break-point.ast-replace-site-logo-transparent.ast-theme-transparent-header .transparent-custom-logo {
    display: inline-block
  }

  .ast-theme-transparent-header .ast-above-header {
    background-image: none;
    background-color: transparent
  }

  .ast-theme-transparent-header .ast-below-header {
    background-image: none;
    background-color: transparent
  }
}

.ast-theme-transparent-header .main-header-bar,
.ast-theme-transparent-header .site-header {
  border-bottom-width: 0
}

.ast-breadcrumbs .trail-browse,
.ast-breadcrumbs .trail-items,
.ast-breadcrumbs .trail-items li {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: none;
  background: inherit;
  text-indent: 0
}

.ast-breadcrumbs .trail-browse {
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit
}

.ast-breadcrumbs .trail-items {
  list-style: none
}

.trail-items li::after {
  padding: 0 .3em;
  content: "»"
}

.trail-items li:last-of-type::after {
  display: none
}

.trail-items li::after {
  content: "→"
}

.ast-breadcrumbs-wrapper,
.ast-breadcrumbs-wrapper a {
  font-family: inherit;
  font-weight: inherit
}

.ast-breadcrumbs-wrapper {
  text-align: left
}

.ast-breadcrumbs-inner #ast-breadcrumbs-yoast,
.ast-breadcrumbs-inner .breadcrumbs,
.ast-breadcrumbs-inner .rank-math-breadcrumb {
  padding-bottom: 10px
}

.ast-header-break-point .ast-breadcrumbs-wrapper {
  order: 4
}

.ast-default-menu-enable.ast-main-header-nav-open.ast-header-break-point .main-header-bar.ast-header-breadcrumb,
.ast-main-header-nav-open .main-header-bar.ast-header-breadcrumb {
  padding-top: 1em;
  padding-bottom: 1em
}

.ast-header-break-point .main-header-bar.ast-header-breadcrumb {
  border-bottom-width: 1px;
  border-bottom-color: #eaeaea;
  border-bottom-style: solid
}

.ast-breadcrumbs-wrapper {
  line-height: 1.4
}

.ast-breadcrumbs-wrapper .rank-math-breadcrumb p {
  margin-bottom: 0
}

.ast-breadcrumbs-wrapper {
  display: block;
  width: 100%
}
.ast-article-post .ast-date-meta .posted-on,
		.ast-article-post .ast-date-meta .posted-on * {
			background: #0274be;
			color: #ffffff;
		}

		.ast-article-post .ast-date-meta .posted-on .date-month,
		.ast-article-post .ast-date-meta .posted-on .date-year {
			color: #ffffff;
		}

		.ast-load-more:hover {
			color: #ffffff;
			border-color: #0274be;
			background-color: #0274be;
		}

		.ast-loader>div {
			background-color: #0274be;
		}

		.ast-separate-container .blog-layout-1,
		.ast-separate-container .blog-layout-2,
		.ast-separate-container .blog-layout-3 {
			background-color: transparent;
			background-image: none;
		}

		.ast-separate-container .ast-article-post {
			background-color: #ffffff;
		}

		.ast-separate-container .ast-article-single,
		.ast-separate-container .comment-respond,
		.ast-separate-container .ast-comment-list li,
		.ast-separate-container .ast-woocommerce-container,
		.ast-separate-container .error-404,
		.ast-separate-container .no-results,
		.single.ast-separate-container .ast-author-meta,
		.ast-separate-container .related-posts-title-wrapper,
		.ast-separate-container.ast-two-container #secondary .widget,
		.ast-separate-container .comments-count-wrapper,
		.ast-box-layout.ast-plain-container .site-content,
		.ast-padded-layout.ast-plain-container .site-content {
			background-color: #ffffff;
		}

		.footer-adv .widget-title,
		.footer-adv .widget-title a.rsswidget,
		.ast-no-widget-row .widget-title {
			font-family: 'Roboto Slab', serif;
			text-transform: inherit;
		}

		.footer-adv .widget>*:not(.widget-title) {
			font-family: 'Roboto Slab', serif;
		}

		@media (max-width:768px) {

			.ast-flyout-menu-enable.ast-main-header-nav-open .main-header-bar,
			.ast-fullscreen-menu-enable.ast-main-header-nav-open .main-header-bar {
				padding-bottom: 1.5em;
			}
		}

		@media (max-width:544px) {

			.ast-flyout-menu-enable.ast-main-header-nav-open .main-header-bar,
			.ast-fullscreen-menu-enable.ast-main-header-nav-open .main-header-bar {
				padding-bottom: 1em;
			}
		}

		@media (min-width:769px) {
			.ast-container {
				max-width: 940px;
			}
		}

		@media (min-width:993px) {
			.ast-container {
				max-width: 940px;
			}
		}

		@media (min-width:1201px) {
			.ast-container {
				max-width: 940px;
			}
		}

		.ast-default-menu-enable.ast-main-header-nav-open.ast-header-break-point .main-header-bar,
		.ast-main-header-nav-open .main-header-bar {
			padding-bottom: 0;
		}

		.ast-fullscreen-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation .main-header-menu>.page_item_has_children>.ast-menu-toggle,
		.ast-fullscreen-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation .main-header-menu>.menu-item-has-children>.ast-menu-toggle {
			right: 0;
		}

		.ast-fullscreen-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation ul.sub-menu .menu-item-has-children>.ast-menu-toggle,
		.ast-fullscreen-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation ul.children .page_item_has_children>.ast-menu-toggle {
			right: 0;
		}

		.ast-fullscreen-menu-enable.ast-header-break-point .ast-above-header-menu ul.sub-menu li.menu-item-has-children>a,
		.ast-default-menu-enable.ast-header-break-point .ast-above-header-menu ul.sub-menu li.menu-item-has-children>a,
		.ast-flyout-menu-enable.ast-header-break-point .ast-above-header-menu ul.sub-menu li.menu-item-has-children>a {
			padding-right: 0;
		}

		.ast-fullscreen-menu-enable.ast-header-break-point .ast-below-header-menu ul.sub-menu li.menu-item-has-children>a,
		.ast-default-menu-enable.ast-header-break-point .ast-below-header-menu ul.sub-menu li.menu-item-has-children>a,
		.ast-flyout-menu-enable.ast-header-break-point .ast-below-header-menu ul.sub-menu li.menu-item-has-children>a {
			padding-right: 0;
		}

		.ast-fullscreen-below-menu-enable.ast-header-break-point .ast-below-header-enabled .ast-below-header-navigation ul.ast-below-header-menu li.menu-item-has-children>a,
		.ast-default-below-menu-enable.ast-header-break-point .ast-below-header-enabled .ast-below-header-navigation ul.ast-below-header-menu li.menu-item-has-children>a,
		.ast-flyout-below-menu-enable.ast-header-break-point .ast-below-header-enabled .ast-below-header-navigation ul.ast-below-header-menu li.menu-item-has-children>a {
			padding-right: 0;
		}

		.ast-fullscreen-below-menu-enable.ast-header-break-point .ast-below-header-navigation .menu-item-has-children>.ast-menu-toggle,
		.ast-fullscreen-below-menu-enable.ast-header-break-point .ast-below-header-menu-items .menu-item-has-children>.ast-menu-toggle {
			right: 0;
		}

		.ast-fullscreen-below-menu-enable .ast-below-header-enabled .ast-below-header-navigation ul.ast-below-header-menu li.menu-item-has-children ul.sub-menu .ast-menu-toggle {
			right: 0;
		}

		.ast-fullscreen-above-menu-enable.ast-header-break-point .ast-above-header-enabled .ast-above-header-navigation ul.ast-above-header-menu li.menu-item-has-children>a,
		.ast-default-above-menu-enable.ast-header-break-point .ast-above-header-enabled .ast-above-header-navigation ul.ast-above-header-menu li.menu-item-has-children>a,
		.ast-flyout-above-menu-enable.ast-header-break-point .ast-above-header-enabled .ast-above-header-navigation ul.ast-above-header-menu li.menu-item-has-children>a {
			padding-right: 0;
		}

		.ast-fullscreen-above-menu-enable.ast-header-break-point .ast-above-header-navigation .menu-item-has-children>.ast-menu-toggle,
		.ast-fullscreen-above-menu-enable.ast-header-break-point .ast-above-header-menu-items .menu-item-has-children>.ast-menu-toggle {
			right: 0;
		}

		.ast-fullscreen-above-menu-enable .ast-above-header-enabled .ast-above-header-navigation ul.ast-above-header-menu li.menu-item-has-children ul.sub-menu .ast-menu-toggle {
			right: 0;
		}

		.ast-no-sidebar.ast-separate-container .entry-content .alignfull {
			margin-right: -0;
			margin-left: -0;
		}

		@media (max-width:768px) {

			.main-header-bar,
			.ast-header-break-point .main-header-bar,
			.ast-header-break-point .header-main-layout-2 .main-header-bar {
				padding-top: 1.5em;
				padding-bottom: 1.5em;
			}

			.ast-default-menu-enable.ast-main-header-nav-open.ast-header-break-point .main-header-bar,
			.ast-main-header-nav-open .main-header-bar {
				padding-bottom: 0;
			}

			.main-navigation ul li a,
			.ast-header-break-point .main-navigation ul li a,
			.ast-header-break-point li.ast-masthead-custom-menu-items,
			li.ast-masthead-custom-menu-items {
				padding-top: 0px;
				padding-right: 20px;
				padding-bottom: 0px;
				padding-left: 20px;
			}

			.ast-header-break-point .main-header-bar .main-header-bar-navigation .page_item_has_children>.ast-menu-toggle,
			.ast-header-break-point .main-header-bar .main-header-bar-navigation .menu-item-has-children>.ast-menu-toggle {
				top: 0px;
				right: calc(20px - 0.907em);
			}

			.ast-fullscreen-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation .main-header-menu>.page_item_has_children>.ast-menu-toggle,
			.ast-fullscreen-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation .main-header-menu>.menu-item-has-children>.ast-menu-toggle {
				right: 0;
			}

			.ast-flyout-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation .main-header-menu>.page_item_has_children>.ast-menu-toggle,
			.ast-flyout-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation .main-header-menu>.menu-item-has-children>.ast-menu-toggle {
				right: calc(20px - 0.907em);
			}

			.ast-flyout-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation .page_item_has_children>.ast-menu-toggle,
			.ast-flyout-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation .menu-item-has-children>.ast-menu-toggle {
				top: 0px;
			}

			.ast-desktop .main-navigation .ast-mm-template-content,
			.ast-desktop .main-navigation .ast-mm-custom-text-content,
			.main-navigation ul.sub-menu li a,
			.main-navigation ul.children li a,
			.ast-header-break-point .main-navigation ul.sub-menu li a,
			.ast-header-break-point .main-navigation ul.children li a {
				padding-top: 0px;
				padding-right: 0;
				padding-bottom: 0px;
				padding-left: 30px;
			}

			.ast-header-break-point .main-navigation ul.children li li a,
			.ast-header-break-point .main-navigation ul.sub-menu li li a {
				padding-left: calc(30px + 10px);
			}

			.ast-header-break-point .main-navigation ul.children li li li a,
			.ast-header-break-point .main-navigation ul.sub-menu li li li a {
				padding-left: calc(30px + 20px);
			}

			.ast-header-break-point .main-navigation ul.children li li li li a,
			.ast-header-break-point .main-navigation ul.sub-menu li li li li a {
				padding-left: calc(30px + 30px);
			}

			.ast-header-break-point .main-navigation ul.children li li li li li a,
			.ast-header-break-point .main-navigation ul.sub-menu li li li li li a {
				padding-left: calc(30px + 40px);
			}

			.ast-header-break-point .main-header-bar .main-header-bar-navigation ul.sub-menu .menu-item-has-children>.ast-menu-toggle,
			.ast-header-break-point .main-header-bar .main-header-bar-navigation ul.children .page_item_has_children>.ast-menu-toggle {
				top: 0px;
				right: calc(20px - 0.907em);
			}

			.ast-fullscreen-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation ul.sub-menu .menu-item-has-children>.ast-menu-toggle {
				margin-right: 20px;
				right: 0;
			}

			.ast-flyout-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation ul.sub-menu .menu-item-has-children>.ast-menu-toggle,
			.ast-flyout-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation ul.children .page_item_has_children>.ast-menu-toggle {
				right: calc(20px - 0.907em);
			}

			.ast-flyout-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation .page_item_has_children .children .ast-menu-toggle,
			.ast-flyout-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation .menu-item-has-children .sub-menu .ast-menu-toggle {
				top: 0px;
			}

			.ast-fullscreen-menu-enable.ast-header-break-point .main-navigation ul.sub-menu li.menu-item-has-children>a,
			.ast-fullscreen-menu-enable.ast-header-break-point .main-navigation ul.sub-menu li.page_item_has_children>a,
			.ast-default-menu-enable.ast-header-break-point .main-navigation ul.sub-menu li.menu-item-has-children>a,
			.ast-default-menu-enable.ast-header-break-point .main-navigation ul.sub-menu li.page_item_has_children>a,
			.ast-flyout-menu-enable.ast-header-break-point .main-navigation ul.sub-menu li.menu-item-has-children>a,
			.ast-flyout-menu-enable.ast-header-break-point .main-navigation ul.sub-menu li.page_item_has_children>a {
				padding-top: 0px;
				padding-bottom: 0px;
				padding-left: 30px;
			}

			.ast-fullscreen-menu-enable.ast-header-break-point .ast-above-header-menu ul.sub-menu li.menu-item-has-children>a,
			.ast-default-menu-enable.ast-header-break-point .ast-above-header-menu ul.sub-menu li.menu-item-has-children>a,
			.ast-flyout-menu-enable.ast-header-break-point .ast-above-header-menu ul.sub-menu li.menu-item-has-children>a {
				padding-right: 0;
				padding-top: 0px;
				padding-bottom: 0px;
				padding-left: 30px;
			}

			.ast-fullscreen-menu-enable.ast-header-break-point .ast-below-header-menu ul.sub-menu li.menu-item-has-children>a,
			.ast-default-menu-enable.ast-header-break-point .ast-below-header-menu ul.sub-menu li.menu-item-has-children>a,
			.ast-flyout-menu-enable.ast-header-break-point .ast-below-header-menu ul.sub-menu li.menu-item-has-children>a {
				padding-right: 0;
				padding-top: 0px;
				padding-bottom: 0px;
				padding-left: 30px;
			}

			.ast-fullscreen-menu-enable.ast-header-break-point .ast-below-header-menu ul a,
			.ast-fullscreen-menu-enable.ast-header-break-point .ast-header-break-point .ast-below-header-actual-nav ul.sub-menu li a,
			.ast-fullscreen-menu-enable.ast-header-break-point .ast-below-header-navigation ul.sub-menu li a,
			.ast-fullscreen-menu-enable.ast-header-break-point .ast-below-header-menu-items ul.sub-menu li a,
			.ast-fullscreen-menu-enable.ast-header-break-point .main-navigation ul.sub-menu li a {
				padding-top: 0px;
				padding-bottom: 0px;
				padding-left: 30px;
			}

			.ast-below-header,
			.ast-header-break-point .ast-below-header {
				padding-top: 1em;
				padding-bottom: 1em;
			}

			.ast-below-header-menu a,
			.below-header-nav-padding-support .below-header-section-1 .below-header-menu>li>a,
			.below-header-nav-padding-support .below-header-section-2 .below-header-menu>li>a,
			.ast-header-break-point .ast-below-header-actual-nav>ul>li>a {
				padding-top: 0px;
				padding-right: 20px;
				padding-bottom: 0px;
				padding-left: 20px;
			}

			.ast-desktop .ast-below-header-menu .ast-mm-template-content,
			.ast-desktop .ast-below-header-menu .ast-mm-custom-text-content,
			.ast-below-header-menu ul a,
			.ast-header-break-point .ast-below-header-actual-nav ul.sub-menu li a {
				padding-top: 0px;
				padding-right: 20px;
				padding-bottom: 0px;
				padding-left: 20px;
			}

			.ast-header-break-point .ast-below-header-actual-nav ul.sub-menu li li a,
			.ast-header-break-point .ast-below-header-menu-items ul.sub-menu li li a {
				padding-left: calc(20px + 10px);
			}

			.ast-header-break-point .ast-below-header-actual-nav ul.sub-menu li li li a,
			.ast-header-break-point .ast-below-header-menu-items ul.sub-menu li li li a {
				padding-left: calc(20px + 20px);
			}

			.ast-header-break-point .ast-below-header-actual-nav ul.sub-menu li li li li a,
			.ast-header-break-point .ast-below-header-menu-items ul.sub-menu li li li li a {
				padding-left: calc(20px + 30px);
			}

			.ast-header-break-point .ast-below-header-actual-nav ul.sub-menu li li li li li a,
			.ast-header-break-point .ast-below-header-menu-items ul.sub-menu li li li li li a {
				padding-left: calc(20px + 40px);
			}

			.ast-default-below-menu-enable.ast-header-break-point .ast-below-header-navigation .menu-item-has-children>.ast-menu-toggle,
			.ast-default-below-menu-enable.ast-header-break-point .ast-below-header-menu-items .menu-item-has-children>.ast-menu-toggle,
			.ast-flyout-below-menu-enable.ast-header-break-point .ast-below-header-navigation .menu-item-has-children>.ast-menu-toggle,
			.ast-flyout-below-menu-enable.ast-header-break-point .ast-below-header-menu-items .menu-item-has-children>.ast-menu-toggle {
				top: 0px;
				right: calc(20px - 0.907em);
			}

			.ast-default-below-menu-enable .ast-below-header-enabled .ast-below-header-navigation ul.ast-below-header-menu li.menu-item-has-children ul.sub-menu .ast-menu-toggle,
			.ast-flyout-below-menu-enable .ast-below-header-enabled .ast-below-header-navigation ul.ast-below-header-menu li.menu-item-has-children ul.sub-menu .ast-menu-toggle {
				top: 0px;
				right: calc(20px - 0.907em);
			}

			.ast-fullscreen-below-menu-enable.ast-header-break-point .ast-below-header-navigation .menu-item-has-children>.ast-menu-toggle,
			.ast-fullscreen-below-menu-enable.ast-header-break-point .ast-below-header-menu-items .menu-item-has-children>.ast-menu-toggle {
				right: 0;
			}

			.ast-fullscreen-below-menu-enable .ast-below-header-enabled .ast-below-header-navigation ul.ast-below-header-menu li.menu-item-has-children ul.sub-menu .ast-menu-toggle {
				right: 0;
			}

			.ast-above-header {
				padding-top: 0px;
				padding-bottom: 0px;
			}

			.ast-above-header-enabled .ast-above-header-navigation .ast-above-header-menu>li>a,
			.ast-header-break-point .ast-above-header-enabled .ast-above-header-menu>li:first-child>a,
			.ast-header-break-point .ast-above-header-enabled .ast-above-header-menu>li:last-child>a {
				padding-top: 0px;
				padding-right: 20px;
				padding-bottom: 0px;
				padding-left: 20px;
			}

			.ast-header-break-point .ast-above-header-navigation>ul>.menu-item-has-children>.ast-menu-toggle {
				top: 0px;
			}

			.ast-desktop .ast-above-header-navigation .ast-mm-custom-text-content,
			.ast-desktop .ast-above-header-navigation .ast-mm-template-content,
			.ast-above-header-enabled .ast-above-header-navigation .ast-above-header-menu li ul a,
			.ast-header-break-point .ast-above-header-enabled .ast-above-header-menu li ul.sub-menu a,
			.ast-above-header-enabled .ast-above-header-menu>li:first-child .sub-menu li a {
				padding-top: 0px;
				padding-right: 20px;
				padding-bottom: 0px;
				padding-left: 20px;
			}

			.ast-header-break-point .ast-above-header-enabled .ast-above-header-menu li ul.sub-menu li a {
				padding-left: calc(20px + 10px);
			}

			.ast-header-break-point .ast-above-header-enabled .ast-above-header-menu li ul.sub-menu li li a {
				padding-left: calc(20px + 20px);
			}

			.ast-header-break-point .ast-above-header-enabled .ast-above-header-menu li ul.sub-menu li li li a {
				padding-left: calc(20px + 30px);
			}

			.ast-header-break-point .ast-above-header-enabled .ast-above-header-menu li ul.sub-menu li li li li a {
				padding-left: calc(20px + 40px);
			}

			.ast-default-above-menu-enable.ast-header-break-point .ast-above-header-navigation .menu-item-has-children>.ast-menu-toggle,
			.ast-default-above-menu-enable.ast-header-break-point .ast-above-header-menu-items .menu-item-has-children>.ast-menu-toggle,
			.ast-flyout-above-menu-enable.ast-header-break-point .ast-above-header-navigation .menu-item-has-children>.ast-menu-toggle,
			.ast-flyout-above-menu-enable.ast-header-break-point .ast-above-header-menu-items .menu-item-has-children>.ast-menu-toggle {
				top: 0px;
				right: calc(20px - 0.907em);
			}

			.ast-default-above-menu-enable .ast-above-header-enabled .ast-above-header-navigation ul.ast-above-header-menu li.menu-item-has-children ul.sub-menu .ast-menu-toggle,
			.ast-flyout-above-menu-enable .ast-above-header-enabled .ast-above-header-navigation ul.ast-above-header-menu li.menu-item-has-children ul.sub-menu .ast-menu-toggle {
				top: 0px;
				right: calc(20px - 0.907em);
			}

			.ast-fullscreen-above-menu-enable.ast-header-break-point .ast-above-header-navigation .menu-item-has-children>.ast-menu-toggle,
			.ast-fullscreen-above-menu-enable.ast-header-break-point .ast-above-header-menu-items .menu-item-has-children>.ast-menu-toggle {
				right: 0;
			}

			.ast-fullscreen-above-menu-enable .ast-above-header-enabled .ast-above-header-navigation ul.ast-above-header-menu li.menu-item-has-children ul.sub-menu .ast-menu-toggle {
				margin-right: 20px;
				right: 0;
			}

			.ast-separate-container .ast-article-post,
			.ast-separate-container .ast-article-single,
			.ast-separate-container .ast-comment-list li.depth-1,
			.ast-separate-container .comment-respond,
			.single.ast-separate-container .ast-author-details,
			.ast-separate-container .ast-related-posts-wrap,
			.ast-separate-container .ast-woocommerce-container {
				padding-top: 1.5em;
				padding-bottom: 1.5em;
			}

			.ast-separate-container .ast-article-post,
			.ast-separate-container .ast-article-single,
			.ast-separate-container .comments-count-wrapper,
			.ast-separate-container .ast-comment-list li.depth-1,
			.ast-separate-container .comment-respond,
			.ast-separate-container .related-posts-title-wrapper,
			.ast-separate-container .related-posts-title-wrapper,
			.single.ast-separate-container .ast-author-details,
			.single.ast-separate-container .about-author-title-wrapper,
			.ast-separate-container .ast-related-posts-wrap,
			.ast-separate-container .ast-woocommerce-container {
				padding-right: 2.14em;
				padding-left: 2.14em;
			}

			.ast-separate-container.ast-right-sidebar #primary,
			.ast-separate-container.ast-left-sidebar #primary,
			.ast-separate-container #primary,
			.ast-plain-container #primary {
				margin-top: 1.5em;
				margin-bottom: 1.5em;
			}

			.ast-left-sidebar #primary,
			.ast-right-sidebar #primary,
			.ast-separate-container.ast-right-sidebar #primary,
			.ast-separate-container.ast-left-sidebar #primary,
			.ast-separate-container #primary {
				padding-left: 0em;
				padding-right: 0em;
			}

			.ast-footer-overlay {
				padding-top: 2em;
				padding-bottom: 2em;
			}

			.ast-small-footer .nav-menu a,
			.footer-sml-layout-2 .ast-small-footer-section-1 .menu-item a,
			.footer-sml-layout-2 .ast-small-footer-section-2 .menu-item a {
				padding-top: 0em;
				padding-right: .5em;
				padding-bottom: 0em;
				padding-left: .5em;
			}
		}

		@media (max-width:544px) {

			.main-header-bar,
			.ast-header-break-point .main-header-bar,
			.ast-header-break-point .header-main-layout-2 .main-header-bar,
			.ast-header-break-point .ast-mobile-header-stack .main-header-bar {
				padding-top: 1em;
				padding-bottom: 1em;
			}

			.ast-default-menu-enable.ast-main-header-nav-open.ast-header-break-point .main-header-bar,
			.ast-main-header-nav-open .main-header-bar {
				padding-bottom: 0;
			}

			.ast-fullscreen-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation .main-header-menu>.page_item_has_children>.ast-menu-toggle,
			.ast-fullscreen-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation .main-header-menu>.menu-item-has-children>.ast-menu-toggle {
				right: 0;
			}

			.ast-desktop .main-navigation .ast-mm-template-content,
			.ast-desktop .main-navigation .ast-mm-custom-text-content,
			.main-navigation ul.sub-menu li a,
			.main-navigation ul.children li a,
			.ast-header-break-point .main-navigation ul.sub-menu li a,
			.ast-header-break-point .main-navigation ul.children li a {
				padding-right: 0;
			}

			.ast-fullscreen-menu-enable.ast-header-break-point .main-header-bar .main-header-bar-navigation ul.sub-menu .menu-item-has-children>.ast-menu-toggle {
				right: 0;
			}

			.ast-fullscreen-menu-enable.ast-header-break-point .ast-above-header-menu ul.sub-menu li.menu-item-has-children>a,
			.ast-default-menu-enable.ast-header-break-point .ast-above-header-menu ul.sub-menu li.menu-item-has-children>a,
			.ast-flyout-menu-enable.ast-header-break-point .ast-above-header-menu ul.sub-menu li.menu-item-has-children>a {
				padding-right: 0;
			}

			.ast-fullscreen-menu-enable.ast-header-break-point .ast-below-header-menu ul.sub-menu li.menu-item-has-children>a,
			.ast-default-menu-enable.ast-header-break-point .ast-below-header-menu ul.sub-menu li.menu-item-has-children>a,
			.ast-flyout-menu-enable.ast-header-break-point .ast-below-header-menu ul.sub-menu li.menu-item-has-children>a {
				padding-right: 0;
			}

			.ast-fullscreen-below-menu-enable.ast-header-break-point .ast-below-header-navigation .menu-item-has-children>.ast-menu-toggle,
			.ast-fullscreen-below-menu-enable.ast-header-break-point .ast-below-header-menu-items .menu-item-has-children>.ast-menu-toggle {
				right: 0;
			}

			.ast-fullscreen-below-menu-enable .ast-below-header-enabled .ast-below-header-navigation ul.ast-below-header-menu li.menu-item-has-children ul.sub-menu .ast-menu-toggle {
				right: 0;
			}

			.ast-above-header {
				padding-top: 0.5em;
			}

			.ast-fullscreen-above-menu-enable.ast-header-break-point .ast-above-header-enabled .ast-above-header-navigation ul.ast-above-header-menu li.menu-item-has-children>a,
			.ast-default-above-menu-enable.ast-header-break-point .ast-above-header-enabled .ast-above-header-navigation ul.ast-above-header-menu li.menu-item-has-children>a,
			.ast-flyout-above-menu-enable.ast-header-break-point .ast-above-header-enabled .ast-above-header-navigation ul.ast-above-header-menu li.menu-item-has-children>a {
				padding-right: 0;
			}

			.ast-fullscreen-above-menu-enable.ast-header-break-point .ast-above-header-navigation .menu-item-has-children>.ast-menu-toggle,
			.ast-fullscreen-above-menu-enable.ast-header-break-point .ast-above-header-menu-items .menu-item-has-children>.ast-menu-toggle {
				right: 0;
			}

			.ast-fullscreen-above-menu-enable .ast-above-header-enabled .ast-above-header-navigation ul.ast-above-header-menu li.menu-item-has-children ul.sub-menu .ast-menu-toggle {
				right: 0;
			}

			.ast-separate-container .ast-article-post,
			.ast-separate-container .ast-article-single,
			.ast-separate-container .ast-comment-list li.depth-1,
			.ast-separate-container .comment-respond,
			.single.ast-separate-container .ast-author-details,
			.ast-separate-container .ast-related-posts-wrap,
			.ast-separate-container .ast-woocommerce-container {
				padding-top: 1.5em;
				padding-bottom: 1.5em;
			}

			.ast-separate-container .ast-article-post,
			.ast-separate-container .ast-article-single,
			.ast-separate-container .comments-count-wrapper,
			.ast-separate-container .ast-comment-list li.depth-1,
			.ast-separate-container .comment-respond,
			.ast-separate-container .related-posts-title-wrapper,
			.ast-separate-container .related-posts-title-wrapper,
			.single.ast-separate-container .ast-author-details,
			.single.ast-separate-container .about-author-title-wrapper,
			.ast-separate-container .ast-related-posts-wrap,
			.ast-separate-container .ast-woocommerce-container {
				padding-right: 1em;
				padding-left: 1em;
			}
		}

		@media (max-width:544px) {

			.ast-header-break-point .header-main-layout-2 .site-branding,
			.ast-header-break-point .ast-mobile-header-stack .ast-mobile-menu-buttons {
				padding-bottom: 0;
			}
		}

		@media (max-width:768px) {

			.ast-separate-container.ast-two-container #secondary .widget,
			.ast-separate-container #secondary .widget {
				margin-bottom: 1.5em;
			}
		}

		@media (max-width:768px) {
			.ast-separate-container #primary {
				padding-top: 0;
			}
		}

		@media (max-width:768px) {
			.ast-separate-container #primary {
				padding-bottom: 0;
			}
		}

		.site-header .ast-sticky-shrunk .ast-site-identity,
		.ast-sticky-shrunk .main-header-menu>li>a,
		.ast-sticky-shrunk li.ast-masthead-custom-menu-items {
			padding-top: 0;
			padding-bottom: 0;
		}

		.ast-header-break-point .ast-sticky-shrunk .main-navigation ul.sub-menu li a,
		.ast-header-break-point .ast-sticky-shrunk .main-navigation ul.children li a {
			padding-top: 0;
			padding-bottom: 0;
		}

		.ast-sticky-shrunk .main-header-menu ul a {
			padding-top: 0.9em;
			padding-bottom: 0.9em;
		}

		.ast-above-header>div,
		.main-header-bar>div,
		.ast-below-header>div {
			-webkit-transition: all 0.2s linear;
			transition: all 0.2s linear;
		}

		.ast-above-header,
		.main-header-bar,
		.ast-below-header {
			max-width: 100%;
		}

		#ast-scroll-top {
			background-color: rgba(2, 116, 190, 0.8);
			font-size: 15px;
			font-size: 1rem;
		}

		.site-title,
		.site-title a {
			font-family: 'Roboto Slab', serif;
			text-transform: inherit;
		}

		.secondary .widget-title {
			font-family: 'Roboto Slab', serif;
			text-transform: inherit;
		}

		.secondary .widget>*:not(.widget-title) {
			font-family: 'Roboto Slab', serif;
		}

		.ast-single-post .entry-title,
		.page-title {
			font-family: 'Roboto Slab', serif;
			text-transform: inherit;
		}

		.ast-archive-description .ast-archive-title {
			font-family: 'Roboto Slab', serif;
			text-transform: inherit;
		}

		.blog .entry-title,
		.blog .entry-title a,
		.archive .entry-title,
		.archive .entry-title a,
		.search .entry-title,
		.search .entry-title a {
			font-family: 'Roboto Slab', serif;
			text-transform: inherit;
		}

		h1,
		.entry-content h1 {
			text-transform: inherit;
		}

		h2,
		.entry-content h2 {
			text-transform: inherit;
		}

		h3,
		.entry-content h3 {
			text-transform: inherit;
		}

		h4,
		.entry-content h4 {
			text-transform: inherit;
		}

		h5,
		.entry-content h5 {
			text-transform: inherit;
		}

		h6,
		.entry-content h6 {
			text-transform: inherit;
		}

		.ast-desktop .ast-mega-menu-enabled.ast-below-header-menu li a:hover,
		.ast-desktop .ast-mega-menu-enabled.ast-below-header-menu li a:focus {
			background-color: #575757;
		}

		.ast-desktop .ast-below-header-navigation .astra-megamenu-li li a:hover,
		.ast-desktop .ast-below-header-navigation .astra-megamenu-li .menu-item a:focus {
			color: #ffffff;
		}

		.ast-above-header-menu .astra-full-megamenu-wrapper {
			box-shadow: 0 5px 20px rgba(0, 0, 0, 0.06);
		}

		.ast-above-header-menu .astra-full-megamenu-wrapper .sub-menu,
		.ast-above-header-menu .astra-megamenu .sub-menu {
			box-shadow: none;
		}

		.ast-below-header-menu.ast-mega-menu-enabled.submenu-with-border .astra-full-megamenu-wrapper {
			border-color: #ffffff;
		}

		.ast-below-header-menu .astra-full-megamenu-wrapper {
			box-shadow: 0 5px 20px rgba(0, 0, 0, 0.06);
		}

		.ast-below-header-menu .astra-full-megamenu-wrapper .sub-menu,
		.ast-below-header-menu .astra-megamenu .sub-menu {
			box-shadow: none;
		}

		.ast-desktop .main-header-menu.submenu-with-border .astra-megamenu,
		.ast-desktop .main-header-menu.ast-mega-menu-enabled.submenu-with-border .astra-full-megamenu-wrapper {
			border-top-width: 2px;
			border-left-width: 0px;
			border-right-width: 0px;
			border-bottom-width: 0px;
			border-style: solid;
		}

		.ast-desktop .ast-mega-menu-enabled.main-header-menu .menu-item-heading>a {
			font-weight: 700;
			font-size: 1.1em;
		}

		.ast-desktop .ast-above-header .submenu-with-border .astra-full-megamenu-wrapper {
			border-top-width: 2px;
			border-left-width: 0px;
			border-right-width: 0px;
			border-bottom-width: 0px;
			border-style: solid;
		}

		.ast-desktop .ast-below-header .submenu-with-border .astra-full-megamenu-wrapper {
			border-top-width: 2px;
			border-left-width: 0px;
			border-right-width: 0px;
			border-bottom-width: 0px;
			border-style: solid;
		}

		.ast-advanced-headers-different-logo .advanced-header-logo,
		.ast-header-break-point .ast-has-mobile-header-logo .advanced-header-logo {
			display: inline-block;
		}

		.ast-header-break-point.ast-advanced-headers-different-logo .ast-has-mobile-header-logo .ast-mobile-header-logo {
			display: none;
		}

		.ast-advanced-headers-layout {
			width: 100%;
		}

		#ast-fixed-header .ast-container {
			max-width: 100%;
			padding-left: 35px;
			padding-right: 35px;
		}

		@media (max-width:921px) {
			#ast-fixed-header .ast-container {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		.ast-primary-sticky-header-active .site-title a,
		.ast-primary-sticky-header-active .site-title a:focus,
		.ast-primary-sticky-header-active .site-title a:hover,
		.ast-primary-sticky-header-active .site-title a:visited {
			color: #222;
		}

		.ast-primary-sticky-header-active .site-header .site-description {
			color: #0a0a0a;
		}

		.ast-transparent-header.ast-primary-sticky-header-active .main-header-bar,
		.ast-primary-sticky-header-active .main-header-bar,
		.ast-primary-sticky-header-active.ast-header-break-point .main-header-bar {
			background: #ffffff;
		}

		.ast-primary-sticky-header-active .main-header-menu li.current-menu-item>a,
		.ast-primary-sticky-header-active .main-header-menu li.current-menu-ancestor>a,
		.ast-primary-sticky-header-active .main-header-menu li.current_page_item>a {
			color: #0274be;
		}

		.ast-primary-sticky-header-active .main-header-menu a:hover,
		.ast-header-custom-item a:hover,
		.ast-primary-sticky-header-active .main-header-menu li:hover>a,
		.ast-primary-sticky-header-active .main-header-menu li.focus>a,
		.ast-primary-sticky-header-active.ast-advanced-headers .main-header-menu>li>a:hover,
		.ast-primary-sticky-header-active.ast-advanced-headers .main-header-menu>li>a:focus {
			color: #0274be;
		}

		.ast-primary-sticky-header-active .main-header-menu .ast-masthead-custom-menu-items a:hover,
		.ast-primary-sticky-header-active .main-header-menu li:hover>.ast-menu-toggle,
		.ast-primary-sticky-header-active .main-header-menu li.focus>.ast-menu-toggle {
			color: #0274be;
		}

		.ast-primary-sticky-header-active .main-header-menu,
		.ast-primary-sticky-header-active .main-header-menu a,
		.ast-primary-sticky-header-active .ast-header-custom-item,
		.ast-header-custom-item a,
		.ast-primary-sticky-header-active li.ast-masthead-custom-menu-items,
		.ast-primary-sticky-header-active li.ast-masthead-custom-menu-items a,
		.ast-primary-sticky-header-active.ast-advanced-headers .main-header-menu>li>a {
			color: #0a0a0a;
		}

		.ast-primary-sticky-header-active .ast-masthead-custom-menu-items .ast-inline-search form {
			border-color: #0a0a0a;
		}