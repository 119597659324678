@import '../../node_modules/react-select/scss/default';

.Select-value, .Select-menu, .Select-option {
  background-color: $gray;
}

// Don't think this one is taking effect
span.Select-value-label {
  color: $white;
}

.Select-option.is-focused {
  background-color: $dark-gray;
  color: $white;
}

.Select-option.is-selected {
  background-color: $dark-gray;
  color: $white
}